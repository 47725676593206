<template>
  <v-theme-provider dark>
    <section id="land-foot-alt">
      <land-img
        :height="wdHeight"
        :gradient="gradient"
        :src="cover"
        flat
        max-width="100%"
        tile
      >
        <v-container
          style="max-width: 1200px; height: 100%;"
        >
          <v-row
            no-gutters
            class="ma-0 fill-height text-center"
            align="center"
            justify="center"
          >
            <v-col
              cols="12"
              md="6"
              sm="12"
            >
              <v-row no-gutters>
                <v-col
                  cols="6"
                >
                  <div
                    class="d-flex flex-column"
                    style="height: 56px;"
                  >
                    <land-title
                      v-if="about.title"
                      :title="about.title"
                      :space="smAndDown ? 3 : 1"
                    />
                    <land-divider
                      v-if="about.title"
                      color="white"
                      width="28"
                      space="4"
                    />
                  </div>
                  <v-container
                    class="pa-0"
                  >
                    <v-row
                      align="end"
                      no-gutters
                      style="height: 80px;"
                    >
                      <template v-if="about.items && about.items.length">
                        <v-col
                          v-for="(abItem, abIndex) in about.items"
                          :key="`ab_${abIndex}`"
                          cols="5"
                        >
                          <div
                            class="d-flex flex-row"
                            :class="[smAndDown ? 'co-text-xs' : 'co-text-sm']"
                            style="cursor: pointer;"
                            @click="onItem(abItem)"
                          >
                            {{ abItem.text }}
                          </div>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col
                  cols="6"
                >
                  <div
                    class="d-flex flex-column"
                    style="height: 56px;"
                  >
                    <land-title
                      v-if="support.title"
                      :title="support.title"
                      :space="smAndDown ? 3 : 1"
                    />
                    <land-divider
                      v-if="support.title"
                      color="white"
                      width="28"
                      space="4"
                    />
                  </div>
                  <v-container
                    class="pa-0"
                  >
                    <v-row
                      align="end"
                      no-gutters
                      style="height: 80px;"
                    >
                      <template v-if="support.items && support.items.length">
                        <v-col
                          v-for="(suItem, suIndex) in support.items"
                          :key="`su_${suIndex}`"
                          cols="5"
                        >
                          <div
                            class="d-flex flex-row"
                            :class="[smAndDown ? 'co-text-xs' : 'co-text-sm']"
                            style="cursor: pointer;"
                            @click="onItem(suItem)"
                          >
                            {{ suItem.text }}
                          </div>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="hidden-sm-and-down"
              cols="2"
            >
              <div
                class="d-flex flex-column align-center"
              >
                <v-img
                  :src="qrcode.src"
                  style="width: 110px;height: 110px;"
                />
                <div
                  class="d-flex flex-row co-text-xs mt-2"
                >
                  {{ qrcode.title }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </land-img>
    </section>
  </v-theme-provider>
</template>

<script>
  import {
    HexToRGBA,
    RGBAtoCSS,
  } from 'vuetify/lib/util/colorUtils'

  import api from '@/api/co.api'
  import web from '@/api/web/co.web'
  import data from '@/data/co.data'

  export default {
    name: 'SectionFoot',
    data () {
      return {
        points: {
          xs: 240,
          sm: 300,
          md: 360,
          lg: 360,
          xl: 360
        },
        about: data.comm.foot.about,
        support: data.comm.foot.support,
        qrcode: data.comm.foot.qrcode,
        cover: data.comm.foot.cover,
        content: {},
        handlers: {
          scene: this.toScene,
          scheme: this.toScheme,
          demo: this.toPrivacy,
          cooperate: this.toCantact,
          intro: this.toIntro,
          manual: this.toIntro,
          upgrade: this.toIntro,
          contact: this.toCantact,
        },
        scrollOpt: {
          duration: 300,
          offset: 0,
          easing: 'easeInOutCubic'
        }
      }
    },
    computed: {
      gradient () {
        const color1 = `${this.$vuetify.theme.themes.light.secondary}88`
        const color2 = `${this.$vuetify.theme.themes.light.secondary}DD`
        const overlay1 = RGBAtoCSS(HexToRGBA(color1))
        const overlay2 = RGBAtoCSS(HexToRGBA(color2))
        // console.log({ overlay1, overlay2 })
        return `to top right, ${overlay1}, ${overlay2}`
      },
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || '360'
        return ht
      },
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    created () {
    },
    methods: {
      toIntro (ev) {
        const target = `#home_${ev.name}`
        this.$vuetify.goTo(target, this.scrollOpt)
      },
      toLogin (ev) {
        const params = {
          mode: 'phone'
        }
        api.page.navigate(this, 'login', params)
      },
      toScene () {
        api.page.navigate(this, 'scene')
      },
      toScheme () {
        api.page.navigate(this, 'scheme')
      },
      toCantact () {
        api.page.navigate(this, 'about')
      },
      onItem (ev) {
        // console.log({ ev })
        const handler = this.handlers[ev.name]
        if (typeof handler === 'function') {
          handler(ev)
        }
      },
      getCover () {
        const me = this
        let params = null
        const executed = function (res) {
          if (res.status) {
            const item = params.items[0] || {}
            me.cover = item.cover || ''
          }
        }
        params = web.banner.getParams({
          type: web.comm.BannerTypes.FOOT_MAJOR,
          currentPage: '1',
          pageSize: '1',
          executed
        })

        api.httpx.getItems(params)
      },
      getContent () {
        const me = this
        let params = null
        const content = {}
        const executed = function (res) {
          // console.log('params.items: %o', params.items)
          if (res.status) {
            const items = params.items
            for (const i in items) {
              const item = items[i]
              content[item.value] = {
                name: item.value,
                title: item.label,
                items: item.content.items || []
              }
            }

            me.content = content
          }
        }

        params = web.dict.getParams({
          type: 'foot',
          executed
        })
        api.httpx.getItems(params)
      }
    }
  }
</script>
